import GameHeaderComponent from '../components/GameHeaderComponent'
import GameBlockIntro from '../components/GameBlockIntro'
import GameBottomButtons from '../components/GameBottomButtons'
import GameQuestions from '../components/GameQuestions'
import GameClue from '../components/GameClue'
import GameCountDown from '../components/GameCountDown'
import GameModal from '../components/GameModal'
import GameResume from '../components/GameResume'
import GameDayEnd from '../components/GameDayEnd'
import './GamePage.css'
import React, { useState, useEffect } from 'react'
import { getDataGame, getBlocksByDay, getQuestionsByIds, startGame, saveAnswer, getRefreshData, gotoSummary, getScore } from '../services/apiService'
import { convertSeconds } from '../services/rankingService'

// response letters map
const responseMap = ['a','b','c','d']

function GamePage({setBodyClass}) {

  //Getting token from local storage
  const userToken = localStorage.getItem('userToken')
  
  const [currentDay, setCurrentDay] = useState(1)
  const [colorOrder, setColorOrder] = useState([])
  const [runningStatus, setRunningStatus] = useState('not-started')
  const [clickOut, setClickOut] = useState(false)
  const [clueOpen, setClueOpen] = useState(false)
  const [clueText, setClueText] = useState(null)
  const [dayBlocks, setDayBlocks] = useState([])
  const [clickedClue, setClickedClue] = useState(false) 
  const [usedClue, setUsedClue] = useState(false)
  const [currentTeamId, setCurrentTeamId] = useState(null)

  const [questionIndex, setQuestionIndex] = useState(0)

  const [allQuestionsBlock, setAllQuestionsBlock] = useState([])
  //const [questionsIds, setQuestionsIds] = useState(null)
  const [blockIndex, setBlockIndex] = useState(0)
  const [scoring, setScoring] = useState({})
  const [trigger, setTrigger] = useState(false)
  const [finalTime, setFinalTime] = useState(0)
  const [totalTime, setTotalTime] = useState("00:00:00")

  // 
  const [buttonClasses, setButtonClasses] = useState(['','','',''])
  const [buttonPenalizations, setButtonPenalizations] = useState([0,0,0,0])
  const [response, setResponse] = useState(null);
  const [thisQuestion, setThisQuestion] = useState(null) // allQuestionsBlock[questionIndex]
  const [disabledButton, setDisableButton] = useState(true)

  const cluePenalty = "60s"

  useEffect(() => {
    if ( runningStatus === 'finished' || runningStatus === 'end') {
      loadTotalTimes()
    }
    const gameInterval = setInterval(refreshData, 3000)
    return () => clearInterval(gameInterval)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allQuestionsBlock, runningStatus])

  // get game details and shufle questions. Should be called just once
  const loadDataGame = async () => {
    const result = await getDataGame(userToken)
    //console.log("shuffle de quipo recibido", result)
    let shuffleData
    if(result.data) {
      shuffleData = JSON.parse(result.data.shuffle)
      const dni = result.data.dni
      //console.log('shuffle recibido:', shuffleData, 'dni:', dni, 'teamId: ', result.data.teamId, result.data.currentDay)
      const day = 'day' + result.data.currentDay;
      setColorOrder(shuffleData[day].colors)
      setCurrentDay(result.data.currentDay)
      //setQuestionsIds(shuffleData[day].questions[blockIndex])
    }

    // get questions and datablocks
    await loadQuestions()
    await loadDataBlocksDay(result.data.currentDay)
  }

  // load block for the current day
  const loadDataBlocksDay = async (day) => {
    const result = await getBlocksByDay(userToken, day)
    //console.log("bloques recibidos", currentDay, result)
    if(result.data) {
      setDayBlocks(result.data)
    }
  }
  
  // this function is called after countdown, to ensure the game has started ¿redundant?
  const onCountdownFinished = async () => {
    const resultStartGame = await startGame(userToken)
    if(resultStartGame.data) {
      updateGameState(resultStartGame.data.scoring, resultStartGame.data.team)
    }
  }

  // load the questions for the current day. Should be called once per block
  const loadQuestions = async () => {
    //console.log("loading questions...", {userToken})

    // reset button classes
    //setButtonClasses(['','','',''])
    setResponse(null)
    setDisableButton(true)
    setButtonPenalizations([0,0,0,0])

    // actually get the data
    const result = await getQuestionsByIds(userToken);
    
    if(result.data) {
      // update the state
      setAllQuestionsBlock(result.data.questions)
      updateGameState(result.data.scoring, result.data.team, result.data.questions)
      setCurrentTeamId(result.data.team.id)
    }
  }

  // this function is called every X seconds and is the game loop
  const refreshData = async () => {
    //console.log(runningStatus)
    // do nothing if the game is not on the questions page
    if (runningStatus !== 'running' && runningStatus !== 'not-started') return
    const result = await getRefreshData(userToken);
    updateGameState(result.data.scoring, result.data.team, result.data.questions, result.data.errors, result.data.usedClues)
  }

  const updateGameState = async (scoring, team, questions = null, errors = [], usedCluesByTeam = []) => {
    // avoid errors if questions are empty. In that case we populate with the current value
    if (questions == null) questions = [...allQuestionsBlock]
    
    // patch for the case in which we have jumped to a new block while seing the resume 
    if (team.blockindex > parseFloat(scoring.signature.charAt(1))){
      // fix locally the blockIndex to show the resume
      setRunningStatus('finished')
      setBlockIndex(parseFloat(scoring.signature.charAt(1)))
      return
    }

    if (scoring){
      // patch to stop managing scoring in case of running status set to finished

      setFinalTime(scoring.time)
      if (scoring.runningStatus==='finished'){
        if (questions.length === 0){
          
          // all is finished. Go to end page
          setRunningStatus('end')
        } else {
          // allow runningStatus update
          setRunningStatus(scoring.runningStatus)
        }
        // stop right here!
        return
      }
      // update runningStatus and scoring
      setRunningStatus(scoring.runningStatus)
      setScoring(scoring)
    }

    // update game data
    setBlockIndex(team.blockIndex)
    let isUsedClue = usedCluesByTeam.some(question => question.questionId === questions[team.questionIndex].id)
    setUsedClue(isUsedClue)
    setQuestionIndex(team.questionIndex)
    setThisQuestion(questions[team.questionIndex])
    setClueText(questions[team.questionIndex]?.clueText)
    // //console.log(errors, questions[team.questionIndex])

    // reparse the button classes array
    let errorsArr = [false, false, false, false]
    let errorTimes = [0,0,0,0]
    errors.map((error) => {
      if (error.questionId === questions[team.questionIndex].id) {
        const pos = responseMap.indexOf(error.selectedAnswer)
        if (pos > -1) {
          errorsArr[pos] = true
          errorTimes[pos] = error.timeSpent
        }
      }
    })
    
    // update button classes if some of the elements are an error
    setButtonClasses(orig => orig.map((item, index) => {
      return errorsArr[index]? 'error': (item === 'error')? '': item
    }))
    setButtonPenalizations(errorTimes)
  }

  // actually start the game
  const startButtonPressed = async () => {
    await startGame(userToken)
    setRunningStatus("running")
  }

  // send clue request to the server
  const askingClue = async () => {
    await saveAnswer(userToken, thisQuestion.id, null, true)
  }

  const loadTotalTimes = async () => {
    const resultScores = await getScore(userToken)
    let tTime = 0;
    let teamScores;
    if(runningStatus === 'end') {
      teamScores = resultScores.data.allScores.filter((score) => currentTeamId === score.teamId && score.signature.startsWith(currentDay))
    } else if (runningStatus === 'finished') {
      teamScores = resultScores.data.allScores.filter((score) => currentTeamId === score.teamId && score.signature.endsWith(blockIndex))
    }
    teamScores.forEach((score) => {
        tTime += score.time
    })
    setFinalTime(tTime)
    setTotalTime(convertSeconds(tTime))
  }

  // check if response is right or wrong
  const checkResponse = async () => {
    // disable button
    setDisableButton(true)
    //Send response to backend and store it!
    const resultAnswer = await saveAnswer(userToken, thisQuestion.id, responseMap[response])
    //console.log(resultAnswer)

    // correct || error
    const correctAnswer = thisQuestion.correctAnswer

    let result = 'error'
    if (correctAnswer === responseMap[response]){
      result = 'correct'
      if (questionIndex >= 4) {
        gotoSummary(userToken)
        setRunningStatus('finished')
      } else {
        // go to next batch of questions, after 2 seconds
        setTimeout(loadQuestions, 2000)
      }
    } else {
      result = 'error'
      // refresh data from server in case other user also clicked an error
      //setTimeout(refreshData, 2000)
    }

    // TODO: refactor this to ensure proper state management
    const btClass = [...buttonClasses]
    btClass[response] = result
    const errorTimes = [...buttonPenalizations]
    errorTimes[response] = resultAnswer.data.answer.timeSpent
    setButtonPenalizations(errorTimes)
    setButtonClasses(btClass)

    return result
  }

  // ensure we are properly logged in
  useEffect(() =>{
    if(!userToken) {
      window.location.href = '/login'
    }
  }, [userToken])

  // first load: get all data
  useEffect(() => {
    loadDataGame()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // manage body class based on runningStatus
  useEffect(() => {
    if (runningStatus === 'end'){
      setBodyClass('end')
    } else {
      setBodyClass(colorOrder[blockIndex])
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorOrder, blockIndex, runningStatus])

  useEffect(() => {
    setResponse(null)
    setDisableButton(true)
    setButtonClasses(['', '', '', ''])
  }, [questionIndex])

  return (
    <div className="game-page"> 
      <GameHeaderComponent scoring={scoring} setClickOut={setClickOut} runningStatus={runningStatus} colorOrder={colorOrder} blockIndex={blockIndex} currentDay={currentDay} currentTeamId={currentTeamId} setTotalTime={setTotalTime} totalTime={totalTime}/>
      {/* INTRO */ } 
      { runningStatus === 'not-started' &&
        <>
          <GameBlockIntro blockIndex={blockIndex} setQuestionIndex={setQuestionIndex} dayBlocks={dayBlocks}/>   
        </>
      }

      {/* QUESTIONS */}
      { runningStatus === 'running' &&
      <>
        { finalTime === 0 &&
          <GameCountDown onCountdownFinished={onCountdownFinished}/>
        }
        <GameQuestions 
          questionIndex={questionIndex}
          allQuestionsBlock={allQuestionsBlock}
          trigger={trigger}
          setTrigger={setTrigger}
          buttonClasses={buttonClasses} 
          setButtonClasses={setButtonClasses}
          buttonPenalizations={buttonPenalizations} 
          setResponse={setResponse}
          thisQuestion={thisQuestion}
          setDisableButton={setDisableButton}
          setRunningStatus={setRunningStatus}/>
        <GameClue clueOpen={clueOpen} setClueOpen={setClueOpen} clueText={clueText}/>
        <GameModal clickOut={clickOut} setClickOut={setClickOut}/>
      </>
      }

      {/* RESUME */}
      { runningStatus === 'finished' &&  
        <GameResume loadQuestions={loadQuestions} setRunningStatus={setRunningStatus} blockIndex={blockIndex} cluePenalty={cluePenalty} dayBlocks={dayBlocks} setTotalTime={setTotalTime}/> 
      }

      {/* END */}
      { runningStatus === 'end' &&  
        <GameDayEnd/> 
      }


      {/* BUTTONS */}
      { runningStatus !== 'finished' &&  
        <GameBottomButtons startButtonPressed={startButtonPressed} runningStatus={runningStatus} setClueOpen={setClueOpen} usedClue={usedClue} setUsedClue={setUsedClue} setRunningStatus={setRunningStatus} questionIndex={questionIndex} setQuestionIndex={setQuestionIndex} cluePenalty={cluePenalty} clickedClue={clickedClue} setClickedClue={setClickedClue} onSubmitAnswer={checkResponse} onSubmitClue={askingClue} disabledButton={disabledButton}/> 
      }
      
    </div>
  );
}

export default GamePage;
