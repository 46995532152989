import './GameHeaderComponent.css';
import doctoraBody from '../assets/dra-reum-body.png';
import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { convertSeconds } from '../services/rankingService'


function GameHeaderComponent({setClickOut, runningStatus, colorOrder, currentDay, scoring, currentTeamId, blockIndex, finalTime, setTotalTime, totalTime}) {

  const [blockColor, setBlockColor] = useState("");
  //const [totalTime, setTotalTime] = useState("00:00:00");
  // const [resumePart, setResumePart] = useState(true);

  useEffect(() => {
    switch(colorOrder[blockIndex]) {
      case 'orange':
        return setBlockColor('Naranja');
      case 'blue':
        return setBlockColor('Azul');
      case 'yellow':
        return setBlockColor('Amarillo');
      case 'pink':
        return setBlockColor('Rosa');
      default:
        return setBlockColor('Naranja');  
    }
  }, [blockIndex, colorOrder])
  
  useEffect(() => {
    const i = setInterval(timerControl, 100);
    return () => {
      clearInterval(i);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scoring, runningStatus])

  useEffect(() => {
    if (runningStatus === 'end') {
      setTotalTime(convertSeconds(finalTime))
    }
  }, [])

  const timerControl = () => {
    let seconds = 0

    if(runningStatus !== 'end') {
      if (scoring && scoring.lastTime){
        seconds = scoring.time
        if (runningStatus === 'running') {
          const lastTime = new Date(scoring.lastTime)
          const now = new Date()
          const diff = Math.round((now.getTime() - lastTime.getTime())/1000)
          seconds += diff
          setTotalTime(convertSeconds(seconds))
        } else {
          //setTotalTime(convertSeconds(0))
          //console.log(convertSeconds(seconds), totalTime)
          setTotalTime(totalTime)
        }
      }
    }
  }

  return (
    <div className="game-header" id={'team-id-' + currentTeamId}>
        <div className='top-info'>
            <h5> <span>{runningStatus !== "end" ? `Blq. ${blockColor}` : "Final"}</span> <span>|</span> <span>Día {currentDay}</span></h5>
            {runningStatus === "running" &&
              <button onClick={() => {setClickOut(true)}} className='gradientLink'>Salir</button>
            }
            {runningStatus !== "running" &&
              <Link className='gradientLink' to='/inicio'>Salir</Link>
            }
            
        </div>
        <div className='time-counter'>
          <p>{totalTime}</p>
          <svg width="18" height="22" viewBox="0 0 18 22" className={"svg_icon " + (runningStatus === "running" ? "running" : "")} xmlns="http://www.w3.org/2000/svg">
            <path d="M6.92308 0C6.15721 0 5.53846 0.614453 5.53846 1.375C5.53846 2.13555 6.15721 2.75 6.92308 2.75H7.61538V4.22813C3.30144 4.88984 0 8.59375 0 13.0625C0 17.9996 4.02837 22 9 22C13.9716 22 18 17.9996 18 13.0625C18 11.2664 17.4678 9.59492 16.5505 8.19844L17.5933 7.16289C18.1341 6.62578 18.1341 5.75352 17.5933 5.21641C17.0524 4.6793 16.174 4.6793 15.6332 5.21641L14.6986 6.14453C13.4827 5.15625 12.0072 4.47734 10.3846 4.22813V2.75H11.0769C11.8428 2.75 12.4615 2.13555 12.4615 1.375C12.4615 0.614453 11.8428 0 11.0769 0H9H6.92308ZM10.0385 8.25V13.75C10.0385 14.3215 9.57548 14.7812 9 14.7812C8.42452 14.7812 7.96154 14.3215 7.96154 13.75V8.25C7.96154 7.67852 8.42452 7.21875 9 7.21875C9.57548 7.21875 10.0385 7.67852 10.0385 8.25Z"/>
          </svg>
        </div>
        {runningStatus === "finished" && 
          <div className='resume-part'>
            <div className='text'>
                <h6>Dra. Reum</h6>
                <p><span>¡Has terminado el bloque! </span>Descansa mientras revisas las respuestas.</p>
            </div>
            <img src={doctoraBody} alt='Dra. Reum Cuerpo'></img>
          </div>
        }
    </div>
  );
}

export default GameHeaderComponent;
