import open from '../assets/icono-abrir.svg';
import './HomeDropDown.css';

function HomeDropDown({dropDownHandler, setDropDownHandler, dropDownButtons, setDropDownClicked, dropDownClicked, affectedDropDown, styleDropDown}) {
  
  const onClickDropDown = (() => {
    
    if(affectedDropDown[dropDownClicked].style.display === "none"){
      affectedDropDown[dropDownClicked].style.display = "block"
      dropDownButtons[dropDownClicked].style.transform = "rotate(180deg)"
    }else{
      affectedDropDown[dropDownClicked].style.display = "none"
      dropDownButtons[dropDownClicked].style.transform = "rotate(0deg)"
    }

    // if(dropDownHandler === "opened"){
    //   setDropDownHandler("")
    // }else{
    //   setDropDownHandler("opened")
    // }

    // if (dropDownButtons[dropDownClicked].style.transform === "rotate(0deg)"){
    //   dropDownButtons[dropDownClicked].style.transform = "rotate(180deg)"
    // }else{
    //   dropDownButtons[dropDownClicked].style.transform = "rotate(0deg)"
    // }

  })

  //const styleDropDown = {transform: `rotate(${0}deg)`}

  return (
    <button onClick={onClickDropDown}><img className="dropdown" style={styleDropDown} src={open} alt="abrir"/></button>
  );
}

export default HomeDropDown;
