import './GameModal.css';
import alertIcon from '../assets/alert-icon.svg';
import React, { useState } from 'react';
import { Link } from "react-router-dom";

function GameModal({clickOut, setClickOut}) {

  return (
    <>
      <div className={clickOut ? "game-modal-overlay open" : "game-modal-overlay"} onClick={() => {setClickOut(false)}}></div>
      <div className={clickOut ? "modal open" : "modal"}>
        <img src={alertIcon} alt="alert icon"/>
        <p>Si decides salir, <span>el juego continuará y el tiempo seguirá contando.</span></p>
        <div className='modal-buttons'>
            <button onClick={() => {setClickOut(false)}} className='gradientButton outlined'>Cancelar</button>
            {/* <a href="#" className='gradientButton outlined'>Cancelar</a> */}
            <Link onClick={() => {setClickOut(false)}} className='gradientButton' to='/inicio'>Aceptar</Link>
            {/* <a href="#" className='gradientButton'>Aceptar</a> */}
        </div>
      </div>
    </>
  );
}

export default GameModal;
