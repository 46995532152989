import escapeReumSingle from '../assets/logo-escapeReum-single.svg';
import './HomeHeaderComponent.css';


function HomeHeader({currentPage, onSectionClicked}) {
  return (
    <div className="homeHeader centerColumn">
      <div className='headerImage center'>
        <img src={escapeReumSingle} alt="logotipo Escape Reum"/>
      </div>
      <div className='headerLinks row'>
        <button onClick={()=>onSectionClicked(0)} className={(currentPage===0)?'selected':''}>Equipo</button>
        <button onClick={()=>onSectionClicked(1)} className={(currentPage===1)?'selected':''}>Ranking</button>
        <button onClick={()=>onSectionClicked(2)} className={(currentPage===2)?'selected':''}>Resultados</button>
      </div>
    </div>
  );
}

export default HomeHeader;
