import './GameAnswerComponent.css';
import React, { useEffect, useState } from 'react';

function GameAnswerComponent({trigger, setTrigger, buttonClasses, setButtonClasses, buttonPenalizations, setResponse, thisQuestion, setRunningStatus, setDisableButton}) {

  const [prevQuestionId, setPrevQuestionId] = useState(0)

  const responseSelected = (event, index) => {
    event?.preventDefault()
    setDisableButton(false)
    const btClass = buttonClasses.map((itemClass, i) => {
      if(i === index) {
        if (itemClass === ''){
          setResponse(index)
          return 'selected'
        }
      } else {
        if (itemClass === 'selected'){
          return ''
        }
      }
      return itemClass
    })
    setButtonClasses(btClass)
  }

  useEffect(() => {
    if(trigger){
      setTrigger(false)
    }
  }, [trigger, setTrigger])

  useEffect(() => {
    if(prevQuestionId !== thisQuestion.id){
      // if question changed, we should reset the classes
      if (thisQuestion){
        setPrevQuestionId(thisQuestion.id)
        setButtonClasses(['', '', '', ''])
      } else {
        setRunningStatus('end')
      }
      //setClickedClue(false)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thisQuestion, setPrevQuestionId, setButtonClasses])

  // TODO: use real times on the error labels
  return (
    <div>
      {thisQuestion && 
        <div className='select-answer-list' data-question-id={thisQuestion.id}>
          <button onClick={event => responseSelected(event, 0)} className={buttonClasses[0]}>
            {thisQuestion.optionA} 
            {buttonClasses[0] === 'error' && <span>+{buttonPenalizations[0]}s</span>}
          </button>
          <button onClick={event => responseSelected(event, 1)} className={buttonClasses[1]}>
            {thisQuestion.optionB}
            {buttonClasses[1] === 'error' && <span>+{buttonPenalizations[1]}s</span>}
          </button>
          <button onClick={event => responseSelected(event, 2)} className={buttonClasses[2]}>
            {thisQuestion.optionC}
            {buttonClasses[2] === 'error' && <span>+{buttonPenalizations[2]}s</span>}
          </button>
          <button onClick={event => responseSelected(event, 3)} className={buttonClasses[3]}>
            {thisQuestion.optionD}
            {buttonClasses[3] === 'error' && <span>+{buttonPenalizations[3]}s</span>}
          </button>
        </div>
      }
    </div>
    
  );
}

export default GameAnswerComponent;
