import './GameBlockIntro.css';
import React, { useEffect } from 'react';


function GameBlockIntro({blockIndex, setQuestionIndex, dayBlocks}) {

  useEffect(() => {
    // scroll to top of the page
    window.scrollTo(0,0)

  }, [])

  return (
    <div className="game-block-intro">
        <h1>{dayBlocks[blockIndex]?.title}</h1>
        <div className='content'>
          {dayBlocks[blockIndex]?.title === "Época de las Sales del Oro" &&
            <>
              <p dangerouslySetInnerHTML={{__html: dayBlocks[blockIndex].introText}}/>
              <p><span>Ahora os toca demostrar vuestro conocimiento sobre las enfermedades y tratamientos reumáticos y trabajar en equipo para resolver las pruebas que os permitan encontrar la llave para avanzar al siguiente nivel. </span></p>
              <p><span>¡Preparaos para explorar la fascinante historia de la reumatología en la primera etapa del EscapeReum!</span></p>
            </>
          }

          {dayBlocks[blockIndex]?.title === "Descubrimiento del Metotrexate" &&
            <>
              <p dangerouslySetInnerHTML={{__html: dayBlocks[blockIndex].introText}}/>
              <p><span>A pesar de estos hallazgos, la Dra. Reum necesita inventar algún compuesto que permita optimizar su efectividad y seguridad. Es necesario que utilicéis vuestras habilidades de resolución de problemas y trabajéis en equipo para encontrar el suplemento que deberá administrarse para optimizar el efecto del metotrexate. </span></p>
              <p><span>Tendréis que demostrar vuestra habilidad para trabajar bajo presión y tomar decisiones críticas para superar el desafío y avanzar a la siguiente etapa.</span></p>
              <p><span> ¿Podrás con ello junto a tu equipo?</span></p>
            </>
          }

          {dayBlocks[blockIndex]?.title === "Tiempos de Terapias Avanzadas" &&
            <>
              <p dangerouslySetInnerHTML={{__html: dayBlocks[blockIndex].introText}}/>
              <p><span>Viajaréis por las universidades y laboratorios que han revolucionado el tratamiento de las enfermedades reumáticas. Al encontrar numerosas dianas potenciales, encuentra dificultad en elegir el tratamiento más adecuado para cada situación.</span></p>
              <p><span>Tendrás que colaborar con tus compañeros para utilizar tus conocimientos que permitan desbloquear el conocimiento del enigma molecular y encontrar la solución al problema de la personalización para avanzar a la siguiente etapa.</span></p>
              <p><span>¡A por el nuevo reto!</span></p>
            </>
          }

          {dayBlocks[blockIndex]?.title === "Reumatología del Futuro" &&
            <>
              <p><span>La Dra. Reum os lleva a un futuro en el que la tecnología y la ciencia han avanzado de manera espectacular y queda cada vez más cerca la píldora panreumática.</span></p>
              <p><span>Os muestra algunas nuevas terapias personalizadas y tecnologías innovadoras que están revolucionando el tratamiento de las enfermedades reumáticas.</span></p>
              <p><span>Tendréis que utilizar vuestra imaginación y creatividad para entender la terapia innovadora que permitirá el abordaje de todas las enfermedades reumáticas.</span></p>
              <p dangerouslySetInnerHTML={{__html: dayBlocks[blockIndex].introText}}/>
            </>
          }


          {/* <p><span>La Dra. Reum os transporta a 1927, cuando ocurre primer intento de usar oro en el tratamiento de enfermedades infecciosas.</span></p>
          <p>El Dr. Landé se basa en que el oro tiene un efecto antiséptico, por lo que lo emplea en pacientes con endocarditis bacteriana y fiebre reumática. Observa cómo las molestias articulares de los pacientes que trata son las que más tienen mejoría. </p>
          <p>Por ello, posteriormente el francés Jaques Forestier decide realizar un ensayo clínico y descubre que alrededor del 70% de los pacientes mejoran de su artritis reumatoide.</p>
          <p><span>Ahora te toca a ti demostrar tu conocimiento sobre las enfermedades y tratamientos reumáticos y trabajar en equipo para resolver las pruebas que te permitan encontrar la llave que te permitirá avanzar al siguiente nivel.</span></p>
          <p><span>¡Prepárate para explorar la fascinante historia de la reumatología en la primera etapa del EscapeReum!</span></p> */}
        </div>
    </div>
  );
}

export default GameBlockIntro;
