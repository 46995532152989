import './GameBottomButtons.css';
import lockOpenIcon from "../assets/lock-open-icon.svg"
import { Link } from "react-router-dom";

function GameBottomButtons({runningStatus, setClueOpen , usedClue, setUsedClue, startButtonPressed, questionIndex, setQuestionIndex, cluePenalty, clickedClue, setClickedClue, onSubmitAnswer, onSubmitClue, disabledButton}) {

  const clueText = "+" + cluePenalty;

  const onAskingClue = (e) => {
    e.preventDefault()
    setClueOpen(true) 
    setClickedClue(true)
    setUsedClue(true)
    if(!usedClue){ 
      onSubmitClue()
    }
  }

  return (

    <div className="game-bottom-buttons">
      {/* INTRO */}
      {runningStatus === "not-started" && 
        <div onClick={startButtonPressed} className='gradientButton not-started'>
            <button >¡Empezar!</button>
        </div>
      }

      {/* QUESTIONS */}
      {runningStatus === "running" && 
        <div className='question-buttons'>
            <button className='gradientButton outlined' onClick={(e) => {onAskingClue(e)}}> Pista {usedClue ? <img className='lock-open-icon' src={lockOpenIcon} alt="icono candado abierto"/> : clueText}</button>
            <button onClick={onSubmitAnswer} className={(disabledButton) ? "gradientButton disable" : "gradientButton"}>Responder</button>
        </div>
      }

      {/* DAY END */}
      {runningStatus === "end" && 
        <div className='gradientButton finished'>
          <Link to='/inicio'>Terminar</Link>
        </div>
      }
    </div>
  );
}

export default GameBottomButtons;
