import './GameQuestionProgress.css';

function GameQuestionProgress({questionIndex}) {


  return (
    <div className='question-progress'>
        <div className={"progress-bar q-" + (questionIndex + 1)}><span></span></div>
        <h6>Pregunta {questionIndex + 1}/5</h6>
    </div>
  );
}

export default GameQuestionProgress;
