import './GameClue.css';
import closeIcon from '../assets/close-icon.svg';

function GameClue({clueOpen, setClueOpen, clueText}) {
  //console.log(usedClue, 'usedClue')
  return (
    
    <div className={clueOpen ? "game-clue open" : "game-clue"}>
      <button onClick={() => {setClueOpen(false)}}><img src={closeIcon} alt="close icon"/></button>
      <p>{clueText}</p>
    </div>
  );
}

export default GameClue;
