import './GameResume.css';

function GameResumeIncorrect({resumeUserNick, resumeAnswer, resumeAnswerPenalty}) {

  return (
    <div className='answer error'>
      <div className='information'>
        <h6>{resumeUserNick}</h6>
        <p>+{resumeAnswerPenalty}</p>
      </div>
      <p>{resumeAnswer}</p>
    </div>
  )
}

export default GameResumeIncorrect;
