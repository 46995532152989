import './OnboardingHeaderComponent.css';
import logoCongreso from '../assets/logo-congreso.svg';
import logoSER from '../assets/logo-SER.svg';


function HeaderComponent() {
  return (
    <div className="headerOnBoarding">
        <div className='ser'>
          <p>Organizado por</p>
          <img src={logoSER} alt="Logo SER" />
        </div>
      <img src={logoCongreso} alt="Logo" />
    </div>
  );
}

export default HeaderComponent;
