import './GameQuestions.css';
import React, { useEffect } from 'react';
import GameQuestionProgress from './GameQuestionProgress';
import GameAnswerComponent from './GameAnswerComponent';


function GameQuestions({questionIndex, allQuestionsBlock, trigger, setTrigger, buttonClasses, setButtonClasses, buttonPenalizations, setResponse, thisQuestion, setDisableButton, setRunningStatus}) {

  // const [question, setQuestion] = useState("¿Qué porcentaje de los pacientes con SAPHO del registro SORCOM presentaron dactilitis?");

  useEffect(() => {
    // scroll to top of the page
    window.scrollTo(0,0)
  }, [questionIndex])

  return (
    <div className="game-questions">
      <GameQuestionProgress questionIndex={questionIndex}/>
      <div className='question-answers'>
          <h4>{allQuestionsBlock[questionIndex]?.questionText}</h4>
          <GameAnswerComponent 
            buttonClasses={buttonClasses} 
            buttonPenalizations={buttonPenalizations} 
            setButtonClasses={setButtonClasses}
            setResponse={setResponse}
            thisQuestion={thisQuestion}
            trigger={trigger} 
            setTrigger={setTrigger}
            setDisableButton={setDisableButton}
            setRunningStatus={setRunningStatus}/>
      </div>
    </div>
  );
}

export default GameQuestions;
