import './GameCountDown.css';
import React, { useState, useEffect } from 'react';

function GameCountDown({onCountdownFinished}) {

  const [countDownNumber, setCountDownNumber] = useState(3);

  useEffect(() => {

    const countDown = setInterval(() => {
      //console.log(countDownNumber)
      if(countDownNumber < 0) {
        clearInterval(countDown)
        onCountdownFinished()
      } else {
        setCountDownNumber( (c) => c - 1 )
      }
    }, 1000);
    
    return () => clearInterval(countDown);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[countDownNumber])

  return (

    <div className={countDownNumber <= 0 ? "count-down" : "count-down open"}>
      <div><span>{countDownNumber}</span></div>
    </div>
  );
}

export default GameCountDown;
