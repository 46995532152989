import './GameResume.css';

function GameResumeCorrect({resumeUserNick, resumeAnswer}) {

  return (
    <div className='answer correct'>
      <div className='information'>
        <h6>{resumeUserNick}</h6>
      </div>
      <p>{resumeAnswer}</p>
    </div>
  );
}

export default GameResumeCorrect;
