import OnboardingPage  from './pages/OnboardingPage.js';
import LogInPage from './pages/LogInPage.js';
import InstructionsPage from './pages/InstructionsPage.js';
import HomePage from './pages/HomePage.js';
import GamePage  from './pages/GamePage.js';
import RankingTop5 from './pages/RankingTop5.js';
import './App.css';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";



const setBodyClass = (bodyClass) => {
  const bodyOnboarding = document.querySelector("body")
  bodyOnboarding.setAttribute('class', bodyClass)
}

const router = createBrowserRouter([
  {
    path: "/",
    element: <OnboardingPage setBodyClass={setBodyClass} />,
  },
  {
    path: "/login",
    element: <LogInPage setBodyClass={setBodyClass} />,
  },
  {
    path: "/instrucciones",
    element: <InstructionsPage setBodyClass={setBodyClass} />,
  },
  {
    path: "/inicio",
    element: <HomePage setBodyClass={setBodyClass} />,
  },
  {
    path: "/juego",
    element: <GamePage setBodyClass={setBodyClass} />,
  },
  {
    path: "/ranking",
    element: <RankingTop5 setBodyClass={setBodyClass} />,
  },
])


function App() {
  return (
    <div className="App">
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
