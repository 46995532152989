import './OnboardingSkipIntroComponent.css';
import { Link } from "react-router-dom";

function skipIntroComponent({lastSlide, swiper, userToken}) {

  const jumpToLastSlide = () => {
    swiper.slideTo(3)
  }
  return (
      <div className='skipIntro'>
        { lastSlide && 
        <Link className='gradientButton buttonLink' to={ userToken ? '/inicio' : '/login'}>¡Aceptar Reto!</Link>
        }
        { !lastSlide &&
        <button className='gradientLink' onClick={jumpToLastSlide}>Saltar introducción</button>
        }
      </div>
  );
}

export default skipIntroComponent;
