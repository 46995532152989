import logoEscapeReumWhite from '../assets/logo-EscapeReum-white.svg';
import lupa from '../assets/ilustracion-buscar.svg';
import pregunta from '../assets/ilustracion-duda.svg';
import mapa from '../assets/ilustracion-plano.svg';
import tiempo from '../assets/ilustracion-reloj.svg';
import moviles from '../assets/ilustracion-moviles.svg';
import './InstructionsPage.css';
import React, { useEffect } from 'react';
import { Link } from "react-router-dom";


function InstructionsPage({setBodyClass}) {

    useEffect(() =>{
        setBodyClass('instructionsPage')
         // scroll to top of the page
         window.scrollTo(0,0)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      }, [])


  return (
    <div className='instructions-page centerColumn'>
        <div className='header centerColumn'>
            <img src={logoEscapeReumWhite} alt="Logo Escape Reum"/>
            <h1>INSTRUCCIONES<br></br>DEL JUEGO</h1>
        </div>

        <div className='section'>
            <div>
                <img src={lupa} alt="buscar" />
                <p><span>Os damos la bienvenida al reto de Escape Reum. Sois la esperanza de la reumatología, ¡no nos falléis!</span></p>
            </div>

            <div>
                <img src={pregunta} alt="pregunta" />
                <p>Para demostrar vuestra valía deberéis <span>completar 4 bloques de 5 pruebas</span> cada uno en el menor tiempo posible.</p>
                <p>Pero, ¡no os precipitéis! <span>Cada respuesta errónea y/o el uso de las pistas os penalizará en tiempo general.</span></p>
            </div>

            <div>
                <img src={moviles} alt="móviles" />
                <p>Se puede responder desde todos los móviles, por lo que <span>las respuestas correctas/incorrectas estarán sincronizadas y afectarán a todo el grupo.</span></p>
            </div>

            <div>
                <img src={mapa} alt="mapa" />
                <p>Las soluciones de cada bloque están asociadas/localizadas con/en una de <span>las zonas de pósters que identificaréis por el color.</span></p>
            </div>

            <div>
                <img src={tiempo} alt="reloj" />
                <p>Podéis descasar entre bloques, ese tiempo no se contabiliza, pero una vez empecéis un bloque, <span>cada segundo cuenta</span>, ¡recordad que os enfrentáis a la élite de la reumatología!</p>
            </div>  
        </div>
        <Link className='logInButton buttonLink gradientButton' to='/inicio'>Entendido</Link>
    </div>
  );
}

export default InstructionsPage;
