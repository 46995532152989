// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import "swiper/css/pagination";
import { Pagination } from "swiper";

import logoScapeReum from '../assets/logo-EscapeReum.svg';
import doctora from '../assets/dra-reum-face.png';
import introClock from '../assets/ilustracion-reloj-onboarding.svg';
import bulb from '../assets/ilustracion-bombilla.svg';
import montain from '../assets/ilustracion-montana.svg';
import './OnboardingSectionComponent.css';
import { useEffect, useState } from 'react';


export default function OnboardingSectionComponent({setSwiper, setLastSlide}) {
  
  const formatDate = { month: 'numeric', day: 'numeric' };
  const currentDate = new Date();
  const today = currentDate.toLocaleDateString('es', formatDate);
  const [currentJorney, setCurrentJorney] = useState('primera');

  useEffect(() => {
    const setJorney = () => {
      switch (today) {
        case '11/5':
          return 'segunda';
        case '12/5':
          return 'tercera';
        default:
          return 'primera';
      }
    };

    setCurrentJorney(setJorney());
  }, [today]);
  
  return (
    <div id='swiperReum'>
      <Swiper
        pagination={{
          dynamicBullets: true,
        }}
        modules={[Pagination]}
        className="mySwiper"
        spaceBetween={-1}
        slidesPerView={1}
        onSlideChange={(slideInfo) => setLastSlide( (slideInfo.activeIndex === 3)? true : false ) }
        onSwiper={(swiper) => setSwiper(swiper)}
      >
        <SwiperSlide>
          <div className='slidders' id='slidder1'>
            <img src={logoScapeReum} alt="Logo Escape Reum" className='logoScapeReum'/>
            <img className='reum-image' src={doctora} alt="Doctora" />
            <p><span>¡Bienvenido al EscapeReum!</span></p>
            <p>Estás a punto de embarcarte en un desafío emocionante que te llevará a través de la historia de la reumatología.</p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className='slidders'>
            <img className='intro-image' src={bulb} alt="bombilla" />
            <p>La Dra. Reum, una prestigiosa reumatóloga e investigadora algo excéntrica, <span>ha elegido a tu equipo para demostrar vuestras habilidades y conocimientos </span> en la {currentJorney} jornada de su búsqueda de la píldora panreumática.</p>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className='slidders' id='trophy'>
            <img className='intro-image' src={introClock} alt="reloj" />
            <p><span>Te llevará en un viaje desde la aparición de la reumatología,</span> pasando por la época de las sales del oro, los tratamientos actuales hasta poner un pie en el futuro.</p>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className='slidders'>
            <img className='intro-image' src={montain} alt="montaña" />
            <p><span>En esta aventura, tratará de recuperar la información necesaria para conseguir su ansiado objetivo.</span></p>
            <p>¡Comienza el viaje por el pasado, presente y futuro de la reumatología!</p>
          </div>
        </SwiperSlide>

      </Swiper>

  
</div>
    
  );
};