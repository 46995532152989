import DniComponent from './LogInDniComponent.js'
import './LogInAccountComponent.css'
import { createAccount } from '../services/apiService.js'


function AccountComponent({setCurrentComponent, inputStatus,setInputWrapperStatus, errorMessage, setInputStatus, inputWrapperStatus, setErrorMessage, errorName, setErrorName, changeInputError, currentComponent, dni, setDni, nick, setNick }) {

  const submitForm = async (event) => {
    // take action
    event.preventDefault()
    //console.log("enviando formulario")
    const result = await createAccount(dni, nick)
    if (result.error) {
      if (result.error.includes('DNI')) {
        setErrorName("dni")
        changeInputError(result.error)
      }
      if (result.error.includes('nick')) {
        setErrorName("nick-name")
        changeInputError(result.error)
      }
    } else {
      //Saving uid in local storage
      localStorage.setItem('uid', result.data.dni)

      //console.log("respuesta recibida", result)
      setCurrentComponent('1.1')
    }
  }
  const fieldChanged = (event) => {
    setInputStatus("")
    setInputWrapperStatus("")
    setErrorMessage("")

    // validate nickname
    if (event.target.name === 'nick'){
      let nickName = event.target.value
      event.target.value = nickName.replace(/ /g, "")
      setNick(event.target.value)
    }
    if (event.target.name === 'dni'){
      setDni(event.target.value)
    }
  }
  return (
    <div className="account">
      <form onSubmit={submitForm} autoComplete="off">
        <DniComponent fieldValue={dni} fieldChanged={fieldChanged} 
        inputStatus={inputStatus} inputWrapperStatus={inputWrapperStatus} errorMessage={errorMessage} errorName={errorName}/>
        <div className={"hasLabel " + (errorName === "nick-name" ? inputWrapperStatus : "")}>
          <label className="nickName" htmlFor="nick">Apodo/Nickname</label>
          <input id="nick" name="nick" type="text" maxLength={20} minLength={3} value={nick} className={errorName === "nick-name" ? inputStatus : ""} onInput={fieldChanged} placeholder="draReum" autoComplete="off" required/>
          <span>{errorMessage}</span>
        </div>
        <div className='bottom-button'>
          <button type="submit" className='buttonLink gradientButton'>Crear cuenta</button>
          <p>¿Ya tienes cuenta? <button className='gradientLink' onClick={()=>setCurrentComponent('2')}>Acceder</button></p>
        </div>
      </form>
    </div>
  );
}

export default AccountComponent;
