// function to return color in spanish for english color name

const getColorName = (color) => {
        switch(color) {
          case 'orange':
            return 'Naranja'
          case 'blue':
            return 'Azul'
          case 'yellow':
            return 'Amarillo'
          case 'pink':
            return 'Rosa'
          default:
            return 'Naranja'  
        }
    }

    exports.getColorName = getColorName