import back from '../assets/line-icon.svg';
import './LogInBackButtonComponent.css';

function backButton({onClick}) {
  return (
    <div className='backButton'>
      <a onClick={onClick} id='backLink' className='gradientLink'><img src={back} alt="<"/> Volver</a>
    </div>
  );
}

export default backButton;
