
const apiRoot = 'https://escapereum.ser.es/api'
//const apiRoot = 'http://localhost:3000/api'

const login = async (dni) => {
  const data = {dni}
  const result = await apiWrapper(`/user/login`, 'POST', data)
  return result
}

const createAccount = async (dni, nick) => {
  const data = {dni, nick}
  const result = await apiWrapper(`/user`, 'POST', data)
  return result
}

const verifyToken = async (token) => {
  const data = {token}
  const result = await apiWrapper(`/user/verify`, 'POST', data)
  return result
}

const createTeam = async (name, uid) => {
  const data = {name, uid}
  const result = await apiWrapper(`/team`, 'POST', data)
  return result
}

const joinTeam = async (code, uid) => {
  const data = {code, uid}
  const result = await apiWrapper(`/team/join`, 'POST', data)
  return result
}

const getDataGame = async (userToken) => {
  const data = {userToken}
  const result = await apiWrapper(`/team/game`, 'POST', data)
  return result
}

const getDataTeam = async (userToken) => {
  const data = {userToken}
  const result = await apiWrapper(`/team/id`, 'POST', data)
  return result
}

const getScore = async (userToken = false) => {
  const data = {userToken}
  const result = await apiWrapper(`/team/score`, 'POST', data)
  return result
}

const getBlocksByDay = async (userToken, day) => {
  const data = {userToken}
  const result = await apiWrapper(`/block/day/${day}`, 'POST', data)
  return result
}

const getQuestionsByIds = async (userToken) => {
  const data = {userToken}
  const result = await apiWrapper(`/block/questions`, 'POST', data)
  return result
}

const getGameResume = async (userToken) => {
  const data = {userToken}
  const result = await apiWrapper(`/block/resume`, 'POST', data)
  return result
}

const getRefreshData = async (userToken) => {
  const data = {userToken}
  const result = await apiWrapper(`/block/refresh`, 'POST', data)
  return result
}


const startGame = async (userToken) => {
  const data = {userToken}
  const result = await apiWrapper(`/block/start`, 'POST', data)
  return result
}

const gotoSummary = async (userToken) => {
  const data = {userToken}
  const result = await apiWrapper(`/block/summary`, 'POST', data)
  return result
}

const saveAnswer = async (userToken, questionId, answer = null, isClue = false) => {
  const data = {userToken, questionId, answer, isClue}
  const result = await apiWrapper(`/answer`, 'POST', data)
  return result
}

const apiWrapper = (path, method = 'GET', data = null) => {
  const requestObject = {
    method: method,
    headers: {
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    },
  }
  if (data) {
    requestObject.body = JSON.stringify(data)
  }
  return fetch(`${apiRoot}${path}`, requestObject).then((response) => response.json())
  .then((response) => {
      //console.log('Success:', response);
      return response
  })
  .catch((error) => {
      console.error('Error: ', error)
      return error
  });
}

export {
  login,
  createAccount,
  verifyToken,
  createTeam,
  joinTeam,
  getDataGame,
  getDataTeam,
  getScore,
  getGameResume,
  getBlocksByDay,
  getQuestionsByIds,
  getRefreshData,
  gotoSummary,
  startGame,
  saveAnswer
}