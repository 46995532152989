const convertSeconds = (seconds) => {
  seconds = Math.max(0, seconds)
  let date = new Date(null)
  date.setSeconds(seconds) // specify value for SECONDS here
  let result = date.toISOString().substring(11, 19)
  return result
}


const groupScores = (fg, isGeneral) => {
  const groupedScores = fg.reduce((sum, score) => {
    const { teamId, name, time, signature, runningStatus } = score
    if (runningStatus !== 'finished') return sum
    const day = parseInt(signature[0])
    const blockIndex = parseInt(signature[1])
    const key = `${teamId}-${day}`
    const newSum = {...sum}
    if (!newSum[key]) {
      newSum[key] = { teamId, name, day, blockIndex, time: 0, signature, runningStatus, counter: 0 }
    }
    newSum[key].time += time
    newSum[key].counter += 1
    return newSum
  }, {})

  const sortedScores = Object.values(groupedScores).sort((a, b) => { 
    return (a.time - b.time) + (b.counter - a.counter) * 100
  })

  const byDay = sortedScores.filter((score) => score.counter === 4)
  const byTeam = byDay.reduce((sum, score) => {
    const newScore = {...score}
    const key = `${newScore.teamId}`
    const newSum = {...sum}
    if (!newSum[key]) {
      newSum[key] = newScore
    } else {
      newSum[key].time += newScore.time
      newSum[key].counter += newScore.counter
    }
   
    return newSum
  }, {})


  return !isGeneral ? byDay : Object.values(byTeam).sort((a, b) => { 
    return (b.counter - a.counter) * 100 + (a.time - b.time) / 1000
  })
}


// filter and group scores by teamId and sum time
const getGeneralAndTodayScores = (allScoresResult, currentDay) => {
  const filteredGeneral = allScoresResult.filter((score) => score.time > 0 && score.runningStatus==='finished')
  const filteredToday = filteredGeneral.filter((score) => score.signature.startsWith(currentDay))
  
  // Group scores by teamId and sum time
  const groupedGralArray = groupScores(filteredGeneral, true)
  const groupedTodayArray = groupScores(filteredToday, false)
  
  // Set Top 5
  const generalTopFive = groupedGralArray.slice(0, 10)
  const todayTopFive = groupedTodayArray.slice(0, 10)

  return { generalTopFive, todayTopFive, groupedGralArray, groupedTodayArray, filteredGeneral }
}


// get ranking position by team and day
const getRankingPositionByDay = (allScoresResult, teamId, day) => {
  const groupedScores = groupScores(allScoresResult)
  //console.log(groupedScores)
  const filteredScores = groupedScores.filter((score) => score.time > 0 && score.runningStatus==='finished'  && score.signature.startsWith(day))
  if(filteredScores.length === 0) return '---'
  
  
  const position = 1+filteredScores.findIndex((score) => {
      return score.teamId === teamId
  } ) 
  //console.log({filteredScores, teamId, day, position})
  return position
}


export {
  convertSeconds,
  groupScores,
  getGeneralAndTodayScores,
  getRankingPositionByDay
}