import book from '../assets/icono-libro.svg';
import './HomeStartGameComponent.css';
import { Link } from "react-router-dom";

function StartGame({instructionsViewed, setToolTipOpen, toopTipOpen}) {

  const onGameButton = (() => {
    setToolTipOpen(true)
  })

  const loadInstructions = ((e) => {
    e.preventDefault()
    localStorage.setItem('instructionsViewed', true)
    window.location.href = '/instrucciones'
  })

  return (
    <>
      <div className={'tooltip-overlay ' + (toopTipOpen ? "open" : "")} onClick={(() => {setToolTipOpen(false)})}></div>
      <div className={'tooltip ' + (toopTipOpen ? "open" : "")} onClick={(() => {setToolTipOpen(false)})}>
          <p>Antes de empezar, echa un vistazo a las instrucciones.</p>
      </div>
      <div className="startGame row">
        <Link className='centerColumn col-40 instructions gradientLink' onClick={(e)=> {loadInstructions(e)}}><img src={book} alt="book icon"/>Instrucciones</Link>
        {instructionsViewed && 
          <Link className='gradientButton col-60 center' to='/juego'>Jugar</Link>
        }
        {!instructionsViewed && 
          <button className='gradientButton col-60 center' onClick={onGameButton}>Jugar</button>
        }
      </div>
    </>
  );
}

export default StartGame;
