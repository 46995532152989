import OnboardingHeaderComponent from '../components/OnboardingHeaderComponent'
import OnboardingSectionComponent from '../components/OnboardingSectionComponent'
import SkipIntroComponent from '../components/OnboardingSkipIntroComponent.js'
import './OnboardingPage.css'
import React, { useState, useEffect } from 'react'



function OnboardingPage({setBodyClass}) {

  const userToken = localStorage.getItem('userToken') ? localStorage.getItem('userToken') : null
  const [lastSlide, setLastSlide] = useState(false)
  const [swiper, setSwiper] = useState(null)

  useEffect(() =>{
    setBodyClass('onboardingPage')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  return (
    <div className="onboarding-page">
      <OnboardingHeaderComponent />
      <OnboardingSectionComponent setSwiper={setSwiper} setLastSlide={setLastSlide} />
      <SkipIntroComponent lastSlide={lastSlide} swiper={swiper} userToken={userToken} />
    </div>
  );
}

export default OnboardingPage;
