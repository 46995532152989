import HomeHeader from '../components/HomeHeaderComponent'
import StartGame from '../components/HomeStartGameComponent'
import group from '../assets/icono-grupo.svg'
import ranking from '../assets/icono-ranking.svg'
import rankingDisabled from '../assets/icono-ranking-gris.svg'
//import open from '../assets/icono-abrir.svg'
import trophy from '../assets/icono-trofeo.svg'
import clock from '../assets/icono-reloj.svg'
import clockDisabled from '../assets/icono-reloj-gris.svg'
import './HomePage.css'
import React, { useState, useEffect } from 'react'
import { getDataTeam, getScore } from '../services/apiService'
import { Swiper, SwiperSlide } from 'swiper/react'
import HomeDropDown from '../components/HomeDropDown'
import { convertSeconds, getGeneralAndTodayScores, getRankingPositionByDay } from '../services/rankingService'
import { getColorName } from '../services/utils'


const firstDay = new Date('2023-05-07')
const today = new Date()
const arrDays = ['10 de mayo', '11 de mayo', '12 de mayo']

function HomePage({setBodyClass}) {

  const [swiper, setSwiper] = useState(null)
  const [currentPage, setCurrentPage] = useState(0)
  const [selectedRanking, setSelectedRanking] = useState('general')

  const dropDownButtons = document.querySelectorAll(".dropdown")
  const affectedDropDown = document.querySelectorAll(".affected")
  const [dropDownHandler, setDropDownHandler] = useState("") 
  const [dropDownClicked, setDropDownClicked] = useState()

  dropDownButtons.forEach((dropdown,index) => {
    dropdown.addEventListener("click", function(){
      setDropDownClicked(index)
    })
  })

  // const styleAffected = {display: `block`}

  const [toopTipOpen, setToolTipOpen] = useState(false) //to control if instructions are opened or closed
  const [currentTeamId, setCurrentTeamId] = useState(0)
  const [teamName, setTeamName] = useState('') 
  const [teamCode, setTeamCode] = useState('') 
  const [userNick, setUserNick] = useState('')
  const [userId, setUserId] = useState(0)
  const [usersTeam, setUsersTeam] = useState([])
  const [numUsersTeam, setNumUsersTeam] = useState(0)
  const [generalTopFive, setGeneralTopFive] = useState([])
  const [todayTopFive, setTodayTopFive] = useState([])
  const [visibleTopFive, setVisibleTopFive] = useState([])
  const [currentDay, setCurrentDay] = useState(1+Math.floor( (today - firstDay) / (1000 * 60 * 60 * 24)))
  const [generalPosition, setGeneralPosition] = useState(0)
  const [todayPosition, setTodayPosition] = useState(0)
  const [teamPosition, setTeamPosition] = useState(0)
  const [currentTeamScores, setCurrentTeamScores] = useState([])
  const [teamDaysPlayed, setTeamDaysPlayed] = useState([])
  const [teamTotalTimeByDay, setTeamTotalTimeByDay] = useState(0)
  const [shuffle, setShuffle] = useState([])
  const [allScores, setAllScores] = useState([])
  //Getting token from local storage
  const userToken = localStorage.getItem('userToken')
  const instructionsViewed = localStorage.getItem('instructionsViewed')

  const loadScores = async (teamId) => {
    const scoreResult = await getScore(userToken)
    //console.log('Score recibido: ', scoreResult)
    
    if (!scoreResult.error){

      setCurrentDay(scoreResult.data.currentDay)
      setAllScores(scoreResult.data.allScores)
      const {generalTopFive, todayTopFive, groupedGralArray, groupedTodayArray } = getGeneralAndTodayScores( scoreResult.data.allScores, currentDay)

      // Set Top 5
      setGeneralTopFive(generalTopFive)
      setVisibleTopFive(generalTopFive)
      setTodayTopFive(todayTopFive)
      
      //console.log('groupedTodayArray', groupedTodayArray)

      // Set team ranking position
      const gen =  1 + groupedGralArray.findIndex((score) => score.teamId === teamId)
      setGeneralPosition(gen)
      //const tod = 1 + groupedTodayArray.findIndex((score) => score.teamId === teamId)
      setTodayPosition(getRankingPositionByDay(scoreResult.data.allScores, teamId, currentDay))

      return (scoreResult.data.allScores)
    } else {
      //console.log('Error al cargar los scores: ', scoreResult.error)
    }
  }
  
  const loadTeamData = async () => {
    // Get team data by userToken
    const result = await getDataTeam(userToken)

    if (result.error){
       //("error petición user y team: ", result.error)
        return
    } else {
        //console.log("respuesta recibida", result)
        const dataTeam = result.data.team
        const dataUser = result.data.user

        setCurrentDay(result.data.currentDay)
        setCurrentTeamId(result.data.team.id)
        setTeamName(result.data.team.name)
        setTeamCode(result.data.team.code)
        setUserId(result.data.user.id)
        setUserNick(result.data.user.nick)
        setUsersTeam(result.data.usersTeam)
        setNumUsersTeam(result.data.usersTeam.length)

        setShuffle(JSON.parse(result.data.team.shuffle))

        const allScores = await loadScores(result.data.user.teamId)
        await loadDataTeamByDays({allScores, dataTeam, dataUser})
    }
  }

  const loadDataTeamByDays = async ({allScores, dataUser}) => {

      if (allScores === undefined) return

      const blocksPerDay = [0,0,0]
      allScores.map((score) => {
        if(score.teamId === dataUser.teamId && (score.runningStatus == 'finished' || score.runningStatus == 'end' )) {
          const day = parseInt(score.signature.substring(0, 1)) - 1
          blocksPerDay[day] += 1
        }
      })
      const daysPlayed = blocksPerDay.reduce((accumulator, numBlocks, index) => {
        const newAccum = [...accumulator]
        if (numBlocks > 0) {
          newAccum.push(index+1)
          return newAccum
        } else {
          newAccum.push(0)
          return newAccum
        }
      }, [])
      setTeamDaysPlayed(daysPlayed)

      // Filter scrores by teamId
      //const filteredTeamScores = allScores.filter((score) => score.time > 0 && score.teamId === dataUser.teamId)
      const filteredTeamScores = allScores.filter((score) => score.teamId === dataUser.teamId)
      setCurrentTeamScores(filteredTeamScores)

      // set total time for each day
      const totalTimeByDay = []

      daysPlayed.forEach((day) => {
        const filteredByDay = filteredTeamScores.filter((score) => parseInt(score.signature.substring(0, 1)) === day)
        const totalDayTime = filteredByDay.reduce((accumulator, currentValue) => {
          return accumulator + currentValue.time
        }, 0)
        totalTimeByDay[day] = totalDayTime
      })
      setTeamTotalTimeByDay(totalTimeByDay)

  }


  useEffect(() =>{
    // scroll to top of the page
    window.scrollTo(0,0)
  }, [currentPage])


  useEffect(() =>{
    if(!userToken) {
      window.location.href = '/login'
    }
  }, [userToken])


  useEffect(() =>{
    setBodyClass('homePage')
    loadTeamData()
    // scroll to top of the page
    window.scrollTo(0,0)

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  useEffect(() => {
    const visibleTopFive = (selectedRanking === 'general') ? generalTopFive : todayTopFive
    setVisibleTopFive(visibleTopFive)

    const teamPosition = (selectedRanking === 'general') ? generalPosition : todayPosition
    setTeamPosition(teamPosition)
  }, [selectedRanking, generalTopFive, todayTopFive, generalPosition, todayPosition])

  useEffect(() => {
    loadScores(currentTeamId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRanking])

  const sectionClicked = (section) => {
    if(section === 1) {
      loadScores(currentTeamId)
    }

    swiper.slideTo(section)
  }

  return (
    <div className="home-page">
      <HomeHeader currentPage={currentPage} onSectionClicked={sectionClicked}/>

      <div className='content'>
        <Swiper
          className="mySwiper"
          spaceBetween={-1}
          slidesPerView={1}
          onSlideChange={(slideInfo) => setCurrentPage(slideInfo.activeIndex) }
          onSwiper={(swiper) => setSwiper(swiper)}
        >
          <SwiperSlide>
            <div className='homeTeam'>
              <div className='generalDescription row'>
                <h2>{teamName}</h2>
                <div className='row'>
                  <p>{numUsersTeam}/4</p>
                  <img src={group} alt="equipo"/>
                </div>
              </div>
              <div>
                <ul>
                  <li>{userNick + ' (tú)'} </li>
                  {usersTeam.map((user, index) => {
                    if(user.id !== userId ) {
                      return <li key={index}>{user.nick}</li>
                    }
                    return null
                  })}
                </ul>
              </div>
              <hr></hr>
              <div>
                <p>Código del grupo</p>
                <p className='code'>{teamCode}</p>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='ranking m-25'>
              <div className='rankingTime center'>
                <button onClick={()=>setSelectedRanking('general')} className={'buttonLink ' + ((selectedRanking==='general')?'selected':'')}>General</button>
                <button onClick={()=>setSelectedRanking('hoy')} className={'buttonLink ' + ((selectedRanking!=='general')?'selected':'')}>Hoy</button>
              </div>
              <div className='rankingPosition'>
                <h2>Tu posición</h2>
                <div>
                  <p>{teamPosition !== 0 ? teamPosition : '---'}</p>
                  <img src={ranking} alt="ranking" />
                </div>
                {(teamPosition === 0 || teamPosition === '---') &&
                  <span className='explanation-message'>Completa todos los bloques del día para poder puntuar en la clasificación.</span>
                } 
              </div>
              
              <div className='top5'>
                <div className='row a-center bestPoints'>
                  <h2>Mejor puntuados</h2>
                  <HomeDropDown dropDownHandler={dropDownHandler} setDropDownHandler={setDropDownHandler} dropDownButtons={dropDownButtons} setDropDownClicked={setDropDownClicked} dropDownClicked={dropDownClicked} affectedDropDown={affectedDropDown} styleDropDown={{transform: `rotate(${180}deg)`}}/> 
                  {/* <img src={open} alt="abrir"/>             */}
                </div>
                <div className='row score'>
                  {visibleTopFive.length > 0 && 
                    <div className='container'>
                      <div className='row'>
                        <p className='rankingNumber'>1</p>
                        <div className='first a-center'>
                          <div>
                            <p>{visibleTopFive[0]?.name}</p>
                            <span className='ranking-time'>{visibleTopFive[0] && convertSeconds(visibleTopFive[0]?.time)}</span>
                            { selectedRanking === 'general' &&
                              <span className='played-days'> {visibleTopFive[0]?.counter/4} {(visibleTopFive[0]?.counter/4) > 1 ? 'días completados': 'día completado' }</span>
                            }
                          </div>
                          <img src={trophy} alt="trofeo" />
                        </div>
                      </div> 
                    </div>
                  }
                  {visibleTopFive.length === 0 &&
                    <span className='explanation-message'>Ningún grupo ha completado aún todos los bloques del día.</span>
                  }
                </div>
              


              {/* hide/show with dropdown */}
              <div className="lastScore affected" style={{display: `block`}}>
                {visibleTopFive.map((teamTop, index) => {
                    if(index > 0) {
                      return (<div key={index} className='row score'>
                        <div className='row'>
                          <p className='rankingNumber'>{index+1}</p>
                          <div className='a-center'>
                            <p>{teamTop.name}</p>
                            { selectedRanking === 'general' &&
                              <span className='played-days'> {teamTop.counter/4} {(teamTop.counter/4) > 1 ? 'días completados': 'día completado' }</span>
                            }
                          </div>
                        </div>
                        <span className='ranking-time'>{visibleTopFive && convertSeconds(teamTop.time)}</span>
                    </div>)
                  }else{
                    return null
                  } }
                )}
               </div>
              </div>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className='homeResult m-25'>
              { teamDaysPlayed.map((day, indexDay) => {
                let currentDayNumber = day
                if (day === 0)  currentDayNumber = (indexDay + 1)

                const numBlocksPlayed = currentTeamScores.filter( block => block.signature.startsWith('' + currentDayNumber)).length
                
                // return only when day is less or equal than current day
                if(currentDayNumber <= currentDay ) {
                  return(
                    
                    <div key={indexDay} className='date'>
                        <div className='row j-space lastDate'>
                          <h2>{arrDays[currentDayNumber - 1]}</h2>
                          <HomeDropDown dropDownHandler={dropDownHandler} setDropDownHandler={setDropDownHandler} dropDownButtons={dropDownButtons} setDropDownClicked={setDropDownClicked} dropDownClicked={dropDownClicked} affectedDropDown={affectedDropDown} styleDropDown={{transform: `rotate(${(currentDayNumber === currentDay)? 180 : 0}deg)`}} /> 
                        </div>
                        <div className='row graphicDate'>
                          <div className='row ranking-icon center'>
                            {currentDayNumber < currentDay && <img src={rankingDisabled} alt="ranking"/>}
                            {currentDayNumber === currentDay && <img src={ranking} alt="ranking"/>}
                            
                            <p>{
                              // add filter to allScores to get only scores of current day
                              getRankingPositionByDay(allScores, currentTeamId, currentDayNumber) ? getRankingPositionByDay(allScores, currentTeamId, currentDayNumber) : "---"
                            }</p>
                          </div>
                          <div className='row clock center'>
                            {currentDayNumber < currentDay && <img src={clockDisabled} alt="clock"/>}
                            {currentDayNumber === currentDay && <img src={clock} alt="clock"/>}
                            <p>{teamTotalTimeByDay[currentDayNumber] ? convertSeconds(teamTotalTimeByDay[currentDayNumber]) : '00:00:00'}</p>
                          </div>
                        </div>
                        {/* hide/show with dropdown */}
                        <div className="dayDate affected" style={ currentDayNumber === currentDay ? {display: `block`} : {display: `none`}}>
                          {(numBlocksPlayed === 0 && currentDayNumber === currentDay) &&
                            <span className='explanation-message'>Aún no has completado ningún bloque en este día.</span>
                          }
                          {(numBlocksPlayed === 0 && currentDayNumber != currentDay) &&
                            <span className='explanation-message'>No se completó ningún bloque del día.</span>
                          }
                          
                          {(currentTeamScores && numBlocksPlayed > 0) &&
                            
                          currentTeamScores.sort((a, b) => {
                            return a.signature.localeCompare(b.signature);
                          })
                          .map((score, index) => {

                            if (score.signature.startsWith(currentDayNumber)) {
                              return (
                                <div key={index} id={score.signature.slice(-1)} className='row j-space blockDate'>
                                  {/* <p className='block'>Blq. {parseInt(score.signature.slice(-1))+1}</p> */}
                                  <p className='block'>Blq. {getColorName(shuffle['day'+currentDayNumber].colors[score.signature.slice(-1)])} </p>
                                  {(score.runningStatus === 'running') &&
                                    <span>Bloque en curso</span>
                                  }
                                  {(score.runningStatus === 'not-started') &&
                                    <p>00:00:00</p>
                                  }
                                  {(score.runningStatus !== 'running' && score.runningStatus !== 'not-started') &&
                                    <p>{convertSeconds(score.time)}</p>
                                  }
                                </div>
                              );
                            } else {
                              return null;
                            }
                          })
                          }
                          {(numBlocksPlayed < 4 && numBlocksPlayed > 0 && currentDayNumber === currentDay) &&
                              <span className='explanation-message'>Completa todos los bloques del día para poder puntuar en la clasificación.</span>
                          }
                          {(numBlocksPlayed < 4 && numBlocksPlayed > 0 && currentDayNumber != currentDay) &&
                              <span className='explanation-message'>No se completaron todos los bloques del día.</span>
                          }
                        </div>
                        <hr></hr>
                    </div>
                  )
                }
                return null
                })}
                
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
      <StartGame instructionsViewed={instructionsViewed} setToolTipOpen={setToolTipOpen} toopTipOpen={toopTipOpen}/>
    </div>
    
  )
}

export default HomePage
