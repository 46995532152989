
function DniComponent({fieldValue, fieldChanged, inputStatus, inputWrapperStatus, errorMessage, errorName}) {

  return (
    <div className={"hasLabel " + (errorName === "dni" ? inputWrapperStatus : "")}>
      <label className="dni" htmlFor="dni">DNI/NIE/Pasaporte</label>
      <input id="dni" className={errorName === "dni" ? inputStatus : ""} name="dni" minLength={6} type="text" value={fieldValue} onChange={fieldChanged} placeholder="12345678A" autoComplete="off" required/>
      <span>{errorMessage}</span>
    </div>
  );
}

export default DniComponent;
