import './GameBlockIntro.css';
import React, { useEffect } from 'react';

function GameDayEnd() {

  useEffect(() => {
    // scroll to top of the page
    window.scrollTo(0,0)
  }, [])

  return (
      <div className="game-block-intro">
          <h1>¡ENHORABUENA!</h1>
          <div className='end-content'>
            <p><span>¡Has completado todos los bloques del día!</span></p>
            <p>En este viaje, habéis descubierto junto a la Dra. Reum la emocionante evolución de la reumatología en las últimas décadas. </p>
            <p>Gracias a la aparición de nuevos tratamientos, <span>avances en la gestión de la enfermedad y tecnologías innovadoras,</span> los pacientes con enfermedades reumáticas tienen más opciones de tratamiento efectivas que nunca antes.</p>
            <p>Desde las sales de oro hasta las terapias avanzadas ¡la revolución es asombrosa! Además, las tecnologías punteras, como las ómicas, la telemonitorización y la inteligencia artificial, están ya permitiendo una <span>atención más personalizada y de mayor calidad </span>para los pacientes en todo el mundo.</p>
            <p>Aunque queda mucho por descubrir en el campo de la reumatología, nos encontramos en un <span>momento emocionante de progreso y esperanza para los pacientes y profesionales de la salud.</span> </p>
            <p><span>¡Enhorabuena por ser parte de esta fascinante revolución!</span></p>
          </div>
      </div>
    );
  }

export default GameDayEnd;
