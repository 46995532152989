import DniComponent from '../components/LogInDniComponent';
import AccountComponent from '../components/LogInAccountComponent';
import BackButton from '../components/LogInBackButtonComponent';
import logoEscapeRoomColor from '../assets/logo-EscapeReum-color.svg';
import people from '../assets/ilustracion-personas.svg';
import copy from '../assets/copy-icon.svg';
import './LogInPage.css';
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { login, createTeam, joinTeam } from '../services/apiService.js'


function LogInPage({setBodyClass}) {
  
  const [currentComponent, setCurrentComponent] = useState('1')
  const [dni, setDni] = useState('')
  const [groupName, setGroupName] = useState('')
  const [groupCode, setGroupCode] = useState('')
  const [newTeamCode, setNewTeamCode] = useState('')
  const [newTeamName, setNewTeamName] = useState('')
  const [copyCode, setCopyCode] = useState(null)
  const [inputStatus, setInputStatus] = useState('')
  const [inputWrapperStatus, setInputWrapperStatus] = useState('')
  const [errorMessage, setErrorMessage] = useState('')
  const [errorName, setErrorName] = useState('')
  const [nick, setNick] = useState('')
  const userToken = localStorage.getItem('userToken')

  const submitLoginForm = async (event) => {
    event.preventDefault()
    const result = await login(dni)
    if (result.error){
      setErrorName("dni")
      changeInputError(result.error)
      return
    } else {

      //Saving token in local storage
      localStorage.setItem('userToken', result.data.token)

      if(result.data.teamId){
        // redirect to home
        window.location.href = '/inicio'
      } else {
        setCurrentComponent('1.1')
      }
    }
  }

  const fieldChanged = (event) => {
    setInputStatus("")
    setInputWrapperStatus("")
    setErrorMessage("")

    if (event.target.name === 'dni'){
      setDni(event.target.value)
    }
    if(event.target.name === 'groupName'){
      setGroupName(event.target.value)
    }
    if(event.target.name === 'groupCode'){
      setGroupCode(event.target.value)
    }
  }

  const submitCreateTeam = async (event) => {
    event.preventDefault()
    //console.log("enviando formulario crear equipo", dni)
    const result = await createTeam(groupName, dni)
    //console.log('result', result)
    if (result.error){
      setErrorName("create-group")
      changeInputError(result.error)
      return
    } else {
      setNewTeamCode(result.data.team.code)
      setNewTeamName(result.data.team.name)
      localStorage.setItem('userToken', result.data.userToken)
      //console.log("respuesta recibida", result)
      setCurrentComponent('1.2.1')
    }
  }

  const submitJoinTeam = async (event) => {
    event.preventDefault()
    //console.log("enviando formulario unirse a equipo")
    //Sending token and groupCode to joinTeam function
    if (groupCode === null || groupCode === ""){
      alert("ERROR: grupo vacío nulo")
      return false
    }
    const result = await joinTeam(groupCode, dni)
    //console.log('result', result)
    if (result.error){
      setErrorName("join-group")
      changeInputError(result.error)
      return
    } else {
      //console.log("respuesta recibida", result.data.userToken)
      localStorage.setItem('userToken', result.data.userToken)
      setNewTeamName(result.data.team.name)
      setCurrentComponent('1.3.1')
    }
  }

  const copyGroupCode = () => {
    navigator.clipboard.writeText(newTeamCode)
    .then(() => {
      setCopyCode("copied");
    })
    .catch(() => {
      setCopyCode("not-copied");    
    })

    setTimeout(() => {
      setCopyCode("");
    }, 2100);
  }

  const changeInputError = (error) => {
    setInputStatus("error")
    setInputWrapperStatus("input-error-message")
    setErrorMessage(error)
  } 

  useEffect(() => {
    //reset error messages
    setInputStatus("")
    setInputWrapperStatus("")
    setErrorMessage("")

    // scroll to top of the page
    window.scrollTo(0,0)

  }, [currentComponent])

  useEffect(() =>{
    if(userToken) {
      window.location.href = '/inicio'
    }

    setBodyClass('loginPage')
    // scroll to top of the page
    window.scrollTo(0,0)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  

  return (
    <div className="log">
      { ( currentComponent === '1' || currentComponent === '2'  ) &&
      <div id='LogIn1' className='logInPages buttonYesLog'>
        <img src={logoEscapeRoomColor} alt="Logo Escape Reum" id='logoColor' />
        { currentComponent === '1' &&
        <div className=''>
          <div className='text'>
            <h2>¡Es la hora de la verdad!</h2>
            <p>Primero rellena estos datos de forma individual y después podrás organizar tu grupo.</p>
          </div>
          <AccountComponent dni={dni} setDni={setDni} nick={nick} setNick={setNick} setCurrentComponent={setCurrentComponent} inputStatus={inputStatus} inputWrapperStatus={inputWrapperStatus} errorMessage={errorMessage} setInputStatus={setInputStatus} setInputWrapperStatus={setInputWrapperStatus} setErrorMessage={setErrorMessage} errorName = {errorName} setErrorName = {setErrorName} changeInputError={changeInputError} currentComponent={currentComponent} />
        </div> 
        }
        { currentComponent === '2' &&
        <div className=''>
          <div className='text'>
            <h2>¡Bienvenido de nuevo!</h2>
            <p>Identifícate para poder seguir con el reto de la Doctora Reum.</p>
          </div>
          <form className='account' onSubmit={submitLoginForm} autoComplete="off">
            <DniComponent fieldValue={dni} fieldChanged={fieldChanged} inputStatus={inputStatus} inputWrapperStatus={inputWrapperStatus} errorMessage={errorMessage} errorName={errorName}/>
            <div className='bottom-button'>
              <button type="submit" className='buttonLink gradientButton'>Acceder</button>
              <p>¿No tienes cuenta? <button className='gradientLink' onClick={()=>setCurrentComponent('1')}>Crear cuenta</button></p>
            </div>
          </form>
        </div>
        }
      </div>
      }

      { currentComponent === '1.1' &&
      <div id='LogIn1-1' className='logInPages center'>
        <img className="peopleImage"src={people} alt="personas"/>
        <h2>¡Bienvenido <span>{nick}</span>!</h2>
          <div className='text'>
            <p>Antes de seguir, ten en cuenta que <b>los grupos tienen una capacidad máxima de 4 personas.</b></p>
            <p>Uno de los integrantes deberá de crear el grupo, y el resto se unirá mediante un código.</p>
          </div>
        <div className='logInButtons'>
          <button onClick={()=>setCurrentComponent('1.2')} className='buttonLink'>Crear grupo</button>
          <button onClick={()=>setCurrentComponent('1.3')} className='buttonLink'>Unirme a un grupo</button>
        </div>
      </div>
      }

    
  { currentComponent === '1.2' &&
    <div id='LogIn1-2' className='logInPages buttonYes '>
      <BackButton onClick={()=>setCurrentComponent('1.1')}/>
      <img className="peopleImage"src={people} alt="personas"/>
      <div className='text'>
        <p>Primero, deberás ponerle un nombre al grupo.</p>
        <p>Una vez creado el grupo, podrán unirse el resto de compañeros.</p>
      </div>
      <form className='account' onSubmit={submitCreateTeam} autoComplete="off">
        <div className={"hasLabel " + (errorName === "create-group" ? inputWrapperStatus : "")}>
          <label className="group-name" htmlFor="groupName">Nombre del grupo</label>
          <input id="groupName" name="groupName" type="text" maxLength={15} minLength={3} className={errorName === "create-group" ? inputStatus : ""} value={groupName} onInput={fieldChanged} placeholder="Los ganadores" autoComplete="off" required/>
          <span>{errorMessage}</span>
        </div>
        <div className='bottom-button'>
            <button type="submit" className='buttonLink gradientButton'>Crear grupo</button>
        </div>
      </form>
    </div>
  }

  { currentComponent === '1.3' &&
      <div id='LogIn1-3' className='logInPages buttonYes '>
      <BackButton onClick={()=>setCurrentComponent('1.1')}/>
      <img className="peopleImage" src={people} alt="personas"/>
        <div className='text'>
          <p>Deberás introducir el código del grupo para poder unirte.</p>
          <p>El código lo obtendrá el creador del grupo, una vez lo haya creado.</p>
        </div>
        <form className='account' onSubmit={submitJoinTeam} autoComplete="off">
          <div className={"inputSingleLine noLabel " + (errorName === "join-group" ? inputWrapperStatus : "")}>
            <input id="groupCode" name="groupCode" type="text" className={errorName === "join-group" ? inputStatus : ""} maxLength={6} minLength={6} value={groupCode} onInput={fieldChanged} placeholder="462YV0" autoComplete="off" required/>
            <span>{errorMessage}</span>
          </div>
          {/* <p className='message-error '>Código inexistente o grupo completo</p> */}
          <div className='bottom-button'>
              <button type="submit" className='buttonLink gradientButton'>Unirme al grupo</button>
          </div>
        </form>
      </div>
    }
        {/* 01. Log in - 1.2.1*/}

    { ( currentComponent === '1.2.1' ||  currentComponent === '1.3.1' ) &&
      <div id='LogIn1-2-1' className='logInPages buttonYes center '>
        
      { currentComponent === '1.2.1' &&
        <div className='create'>
          <img className="peopleImage"src={people} alt="personas"/>
          <div className='text'>
            <h2>Has creado el grupo <br></br> <span>{newTeamName}</span></h2>
          </div>
          <div id='copyLabel' className={copyCode ? copyCode : ""}>
            <p>{newTeamCode}</p>
            {/* <button onClick={() => {navigator.clipboard.writeText(groupCode);}}><img src={copy} alt="copy"/></button> */}
            <button onClick={copyGroupCode}><img src={copy} alt="copy"/></button>
          </div>
          <p>Este es el código del grupo, puedes compartirlo ahora con el resto de participantes, pero <b>también se podrán unir más adelante.</b></p>
        </div>
      }
      
      { currentComponent === '1.3.1' &&
        <div className='join '>
          <img className="peopleImage"src={people} alt="personas"/>
          <h2>¡Enhorabuena!<br></br>Te has unido al grupo<br></br><span>{newTeamName}</span></h2>
        </div>
      }
        <div className='bottom-button'>
            <Link className='buttonLink gradientButton' to='/inicio'>Ir a mi zona</Link>
        </div>

      </div>
    }
    </div>
  );
}

export default LogInPage;
