import './GameResume.css';
import React, { useEffect, useState } from 'react';
import GameResumeCorrect from './GameResumeCorrect';
import GameResumeIncorrect from './GameResumeIncorrect';
import GameResumeClue from './GameResumeClue';
import { getGameResume } from '../services/apiService'
import { convertSeconds } from '../services/rankingService'

function GameResume({loadQuestions, setRunningStatus, blockIndex, cluePenalty, dayBlocks, setTotalTime }) {

  useEffect(() => {
    // scroll to top of the page
    window.scrollTo(0,0)
  }, [])

  const [blockResponses, setBlockResponses] = useState([])

  const keepPlaying = () => {
    setRunningStatus('not-started')
    loadQuestions()
  }
  useEffect(()=>{
    loadResume()
  }, [])

  // TODO: get resume data from current block
  const loadResume = async () => {
    const userToken = localStorage.getItem('userToken')
    const result = await getGameResume(userToken)
    const scoringTime = result.data.scoring.time
    setTimeout(() => {
      setTotalTime(convertSeconds(scoringTime))
    }, 1000);
    const responses = result.data.scoring.questionsList.split(',').map( (answer, index) => {
      answer = parseInt(answer, 10)
      const resps = result.data.answers.filter( resp => resp.questionId === answer)
      //console.log(resps)
      let text = ''
      const userResps = resps.map( resp => {
        text = resp.questionText
        const d = (resp.selectedAnswer)? resp['option' + resp.selectedAnswer.toUpperCase()]:'Pista'
        return {
          user: resp.nick,
          isCorrect: resp.isCorrect,
          isClue: resp.clue,
          text: d,
          timeSpent: resp.timeSpent
        }
      })
      const resp = {
        index,
        id: answer,
        text,
        responses: userResps
      }
      //console.log(resp)
      return resp
    })
    //console.log(responses)
    setBlockResponses(responses)
  } 

  return (
    <div className="game-resume">
      <div className='question-list'>
          { blockResponses.map( (resp, index) => (
            <div key={resp.index}>
              <h5>Pregunta {index + 1}/5</h5>
              <h4>{resp.text}</h4>
              <div className='result-list'>
                {resp.responses.map( (response, index) => {
                  return <div key={index}>
                  {response.isCorrect==1 && 
                  <GameResumeCorrect resumeUserNick={response.user} resumeAnswer={response.text}/>
                  }
                  {response.isClue==1 && <GameResumeClue cluePenalty={cluePenalty}/>}
                  {response.isCorrect!=1 && !response.isClue && 
                  <GameResumeIncorrect resumeUserNick={response.user} resumeAnswer={response.text} resumeAnswerPenalty={response.timeSpent+'s'}/>
                  }
                  </div>
                })}
            </div>
          </div>
            ))
          }
      </div>
      <div className='didYouknow'>
        <hr></hr>
        {dayBlocks[blockIndex] && dayBlocks[blockIndex].closingText !== '' &&
        <div>
          <h3>¿Sabías que?</h3>
          <p dangerouslySetInnerHTML={{__html: dayBlocks[blockIndex]?.closingText}}/>
        </div>
        }
      </div>
      <div className="bottom-button">
        <div className='gradientButton'>
            <button onClick={keepPlaying}>Seguir jugando</button>
        </div>
      </div>
    </div>
  );
}

export default GameResume;
