import './RankingTop5.css'
import escapeReumSingle from '../assets/logo-escapeReum-single.svg'
import trophyIcon from '../assets/icono-trofeo.svg'
import logoCongreso from '../assets/logo-congreso.svg'
import logoSER from '../assets/logo-SER.svg'
//import trophy from '../assets/ilustracion-trofeo.svg'
import React, { useEffect, useState } from 'react'
import doctoraBody from '../assets/dra-reum-body.png'
import qr from '../assets/qr.png'
import { getScore } from '../services/apiService'
import { convertSeconds, getGeneralAndTodayScores } from '../services/rankingService'


function RankingTop5({setBodyClass}) {

  const [rankingTitle, setRankingTitle] = useState("general") // general o diario
  const [generalTopFive, setGeneralTopFive] = useState([])
  const [todayTopFive, setTodayTopFive] = useState([])
  const [visibleTopFive, setVisibleTopFive] = useState([])
  const [currentDay, setCurrentDay] = useState(1)

  // Change ranking screen every 30 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      console.log('rankingTitle: ', rankingTitle)
      if (rankingTitle === 'general'){
        setRankingTitle('diario')
        setVisibleTopFive(todayTopFive)
      } else {
        setRankingTitle('general')
        setVisibleTopFive(generalTopFive)
        loadScores()
      }
    }, 10000)
    return () => clearInterval(interval)
  }, [rankingTitle, generalTopFive, todayTopFive])

  /*
  // Reload scores every minute
  useEffect(() => {
    const interval = setInterval(() => {
      loadScores()
    }, 5000)
    return () => clearInterval(interval)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
*/

  const loadScores = async () => {
    const scoreResult = await getScore(null)
    //console.log('Score recibido: ', scoreResult)
    
    if (!scoreResult.error){
      const cDay = scoreResult.data.currentDay
      setCurrentDay(cDay)
      const { generalTopFive, todayTopFive } = getGeneralAndTodayScores( scoreResult.data.allScores, cDay)

      // Set Top 5
      setGeneralTopFive(generalTopFive)
      setVisibleTopFive(generalTopFive)
      setTodayTopFive(todayTopFive)
      setCurrentDay(scoreResult.data.currentDay)
    } else {
      //console.log('Error al cargar los scores: ', scoreResult.error)
    }
  }

  useEffect(() =>{
    setBodyClass('rankingPage')
    loadScores()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <div className={'ranking-top5 ' + rankingTitle}>
      <div className='ranking-content'>
        <img className='escape-reum-logo' src={escapeReumSingle} alt="Logotipo Escape Reum"/>
        <div className='top5'>
          <div className='title'>
            {rankingTitle === 'general' &&
            <h1>Top 5 <span>{rankingTitle}</span></h1> 
            }
            {rankingTitle === 'diario' &&
            <>
              <h1>Top 5</h1>
              <h2>{rankingTitle}</h2>
            </>
            }
            
          </div>
          {visibleTopFive.length === 0 &&
            <span className='explanation-message'>Ningún grupo ha completado aún todos los bloques del día.</span>
          }
          {visibleTopFive.length > 0 &&
            <div className='ranking-list'>
              <div className='first'>
                <div className='position-info'>
                  <h3>1</h3>
                  <div className='group-info'>
                    <p>{visibleTopFive[0]?.name}</p>
                    <span className='ranking-time'>{visibleTopFive[0] && convertSeconds(visibleTopFive[0]?.time)}</span>
                    { rankingTitle === 'general' &&
                      <span className='played-days'> {visibleTopFive[0]?.counter/4} {(visibleTopFive[0]?.counter/4) > 1 ? 'días completados': 'día completado' }</span>
                    }
                  </div>
                </div>
                <img src={trophyIcon} alt="Icono Trofeo" />
              </div>
              <hr></hr>
              {visibleTopFive.slice(0,5).map((teamTop, index) => {
                if(index > 0) {
                  return (
                    <div key={index} className='not-first'>
                        <div className='group-info'>
                          <h4>{index+1}</h4>
                          <div>
                            <p>{teamTop.name}</p>
                            { rankingTitle === 'general' &&
                              <span className='played-days'> {teamTop.counter/4} {(teamTop.counter/4) > 1 ? 'días completados': 'día completado' }</span>
                            }
                          </div>
                        </div>
                        <span className='ranking-time'>{visibleTopFive && convertSeconds(teamTop.time)}</span>
                    </div>
                  )}else{
                    return null
                  }
              })}
            </div>
          }
        </div>

      </div>
      <img className='dra-image' src={doctoraBody} alt="Imagen Trofeo" />
      <div className='cta-logos'>
        <div className='cta'>
            <h2>¿Te atreves a aceptar el reto de <span>Escape Reum</span>?</h2>
            <p>¡Escanea el QR y te esperamos en la <span>zona de pósteres!</span></p>
            <p>
            <img src={qr} alt="QR"/>
            </p>
        </div>
        <div className='logos'>
          {rankingTitle === "diario" &&
             <img className='logo-XLIX' src={logoCongreso} alt="Logotipo Congreso" />
          }
          {rankingTitle === "general" &&
             <img className='logotipo-SER' src={logoSER} alt="Logotipo SER" />
          }
        </div>
      </div>
    </div>
  )
}

export default RankingTop5
