import './GameResume.css';

function GameResumeClue({cluePenalty}) {

  return (
    <div className='clue'>
        <h6>Pista</h6>
        <p>+{cluePenalty}</p>
    </div>
  );
}

export default GameResumeClue;
